import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  openid:'',
  },
  mutations: {
    SET_OPENID: (state, openid) => {
      state.openid = openid
    },
  },
  actions: {
  },
  modules: {

  }
})
