<template>

<div >
  <van-nav-bar
      title="在线缴纳物业相关费"
      :left-arrow="isarrow"
      @click-left="onClickLeft"

  />
  <h1></h1>



<div>
  <van-cell-group inset>
    <van-cell title="户号信息：" icon="friends-o" v-model="doorname" ></van-cell>
    <van-cell title="缴费单位" icon="shop-o" value="淄博方安物业" ></van-cell>
    <van-cell title="手机" icon="phone-o" v-model="mobile" ></van-cell>
    <van-cell title="账单" icon="balance-list-o" is-link :to="url"  value="查看详情" ></van-cell>

  </van-cell-group>
  <h2 class="van-doc-demo-block__title"></h2>
  <van-cell-group inset class="jaofei">
  <van-cell   center title="缴费金额" icon="gold-coin-o" value=""  label="" >

    <template #title>
      <span class="custom-title">缴费金额</span>

    </template>
    <template #label>
      <span class="custom-title">￥</span>
      <van-tag type="danger">{{totalpay}}</van-tag>
    </template>
  </van-cell>
  </van-cell-group>
  <h2 class="van-doc-demo-block__title"></h2>
  <h2 class="van-doc-demo-block__title"></h2>
  <van-cell-group inset class="jaofei">
  <van-button type="primary" size="large" @click="pay">立即缴费</van-button>
  </van-cell-group>
</div>
  <div class="van-contact-list__bottom">

  </div>
</div>

</template>

<script>
import {Button, Cell, CellGroup,List,NavBar,ContactList,Tag,Popup,Dialog} from 'vant';
import jweixin from 'jweixin-module' ;
import { queryDoorNoList,getUnifiedorderResult} from "@/api/wxmppay"
//import {getRelativeTime} from "@/lib/tools"
import {getOpenid} from '@/utils/auth'


export default {
  name: "pay",

  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]:  CellGroup,
    [List.name]:List,
    [NavBar.name]:NavBar,
    [ContactList.name]:ContactList,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Dialog.name]:Dialog

  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isarrow: true,
      data:{
        getopenid:"",
        doorno:"",
      },
      query:{
        doorno:""
      },
      totalpay:"",
      doorname:"",
      mobile:"",
      url:"",
    };
  },
  created(){
    this.query.doorno=this.$route.query.doorno
    //根据房间号获取欠费数据
    queryDoorNoList(this.query).then(res=>{
   if(res.return_code=="202"){
     Dialog.alert({
       message: res.return_msg,
     }).then(() => {
       // on close
       //this.$router.back(-1)
       this.$router.push('/');
       return;
     });


   }
      if(res.return_code=="201"){
        Dialog.alert({
          message: res.return_msg,
        }).then(() => {
          // on close
          this.$router.push('/');
          return;
        });


      }
    this.totalpay=res.totalpay
    this.doorname=res.doorname
    this.mobile=res.mobile
    this.url="/orderlist/?doorno="+this.query.doorno
    })
  }
  ,
  methods: {
    onLoad() {

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }
      //
      //   // 加载状态结束
      //   this.loading = false;
      //
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    pay(){
      //获取支付信息
      this.data.getopenid=getOpenid()
      this.data.doorno=this.query.doorno
      getUnifiedorderResult(this.data).then(res=>{
        jweixin.config({
          debug: false,
          appId: res.AppId, // 必填，公众号的唯一标识
          timestamp: res.TimeStamp, // 必填，生成签名的时间戳
          nonceStr: res.NonceStr, // 必填，生成签名的随机串
          signature: res.Signature,// 必填，签名
          jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表

        })

        jweixin.chooseWXPay({
          appId:res.AppId,
          timestamp: res.TimeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: res.NonceStr, // 支付签名随机串，不长于 32 位
          package:res.Package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: "MD5", // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign:res.PaySign , // 支付签名
          success: function (res) {
            console.log(res+'tt')
            // 支付成功后的回调函数
            if(res.errMsg === 'chooseWXPay:ok'){
              console.log(res+'yy')
            }
          },// 支付取消回调函数
          cancel: function (res) {
            console.log(res+'jk')
          },
          // 支付失败回调函数
          fail: function (res) {
            console.log(res+"jj")
          }
        })
      })


    },
    onClickLeft(){
      this.$router.push('/');
    },
  },
}

</script>

<style scoped>
.paybg{background: #1677ff;}
.jiaofei{margin-top: 15px;}
</style>