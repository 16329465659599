import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Dialog } from 'vant';
import errorCode from '@/utils/errorCode'
import store from '@/store'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})
// request拦截器
service.interceptors.request.use(config => {

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    console.log(getToken());
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    console.log(config);
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const message = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 401) {
            Dialog.alert(

                { title: '系统提示',
                    message: '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    theme: 'round-button',
                       }
            ).then(() => {
                store.dispatch('LogOut').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            }).catch(() => {
                // on cancel
            })
        } else if (code === 500) {
            Dialog.alert({
                message: message,
                          })
            return Promise.reject(new Error(message))
        } else if (code !== 200) {
            Notification.error({
                title: message
            })
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        Dialog.alert({
            message: error.message,

        })
        return Promise.reject(error)
    }
)

export default service