import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const OpenId="openid"
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getOpenid() {
  return Cookies.get(OpenId)
}

export function setOpenid(openid) {
  return Cookies.set(OpenId, openid)
}

export function removeOpenid() {
  return Cookies.remove(OpenId)
}